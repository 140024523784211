import React from "react";
import Modal from "react-bootstrap/Modal";
import { Input } from "../../../../common/Input";
import MyDropzone from "../../../../common/MyDropzone";
import Select from "react-select/dist/declarations/src/Select";
import UseAddOrUpdateSubAdminForm from "./hooks/UseAddOrUpdateSubAdminForm";
import { IUser } from "../../../../../utils/api/user/user.type";
import ErrorMessage from "../../../../common/ErrorMessage";



function AddOrUpdateSubAdminModal({
    show,
    handleClose,
    admin,
}: {
    show: boolean;
    handleClose: any;
    admin?: IUser;
}) {
    const typeUserOptions = [
        {
            label: "Admin",
            value: "admin",
        },
        {
            label: "Helper",
            value: "helper",
        },
    ];

    const {
        register,
        errors,
        setValue,
        isLoading,
        onSubmit,
        image,
        cardImg,
        handleChangeAvatar
    } = UseAddOrUpdateSubAdminForm(handleClose, admin);

    return (
        <Modal show={show} onHide={handleClose} className="sub_admin__modal ">
            <Modal.Header className="border-0 modal-header-wrapper" closeButton>
                <Modal.Title className="modal-title-custom">
                    {!admin ? "Ajouter un sous admin" : "Modifier un sous admin"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="add-sub-admin-form-wrapper">
                    <form onSubmit={onSubmit}>
                        <div className="form-content">
                            <div className="row custom-row">
                                <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                                    <Input
                                        label="Prenom"
                                        type="text"
                                        customClass="modal__input"
                                        placeholder="Prenom"
                                        id="__first_name"
                                        {...register("prenom")}
                                    />
                                    <ErrorMessage
                                        message={
                                            errors?.prenom &&
                                            errors?.prenom?.message
                                        }
                                    />
                                </div>
                                <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                                    <Input
                                        label="Nom"
                                        type="text"
                                        customClass="modal__input"
                                        placeholder="Nom"
                                        id="__last_name"
                                        {...register("nom")}
                                    />
                                    <ErrorMessage
                                        message={
                                            errors?.nom &&
                                            errors?.nom?.message
                                        }
                                    />
                                </div>
                                <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                                    <Input
                                        label="Email"
                                        type="email"
                                        customClass="modal__input"
                                        placeholder="Email"
                                        id="__email"
                                        {...register("email")}
                                    />
                                    <ErrorMessage
                                        message={
                                            errors?.email &&
                                            errors?.email?.message
                                        }
                                    />
                                </div>
                                <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                                    <label
                                        htmlFor="Type"
                                        className={`form-label custom-form-label`}
                                        aria-labelledby="status"
                                    >
                                        Type
                                    </label>
                                    <select
                                        className="form-select custom-form-control mb-2 modal__input"
                                        id="status"
                                        {...register("user_type")}
                                    >
                                        <option selected disabled>Choisir</option>
                                        <option value={"admin"}>Admin</option>
                                    </select>
                                    <ErrorMessage
                                        message={
                                            errors?.userType &&
                                            errors?.userType?.message
                                        }
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 mb-3 aptm-wrapper">
                                    <Input
                                        label="Téléphone"
                                        type="tel"
                                        required
                                        id="telephone"
                                        {...register("telephone")}
                                    />
                                    <ErrorMessage
                                        message={
                                            errors?.telephone &&
                                            errors?.telephone?.message
                                        }
                                    />
                                </div>
                                {/* <div className="col-md-12 col-lg-12 mb-3 aptm-wrapper">
                                    <Input
                                        label="Avatar"
                                        type="file"
                                        required
                                        id="__avatar"
                                        accept="image/*"
                                        onChange={(e: any) => handleChangeAvatar(e)}
                                        //error={errors?.cover_image?.message}
                                    />
                                    {!!cardImg && (
                                        <img
                                            src={cardImg}
                                            alt="card-img"
                                            style={{
                                                width: "25%",
                                                height: "12vw",
                                                objectFit: "fill",
                                            }}
                                        />
                                    )}
                                </div> */}
                            </div>
                        </div>
                        <div className="pt-3 btn-wrapper d-flex align-items-center justify-content-end gap-3 flex-wrap">
                            <button
                                title="Annuler"
                                type="button"
                                className="btn custom_take__btn modal__btn"
                                onClick={handleClose}
                            >
                                Annuler
                            </button>
                            <button
                                title="Ajouté"
                                type="submit"
                                className="btn modal__btn blue__btn"
                                disabled={isLoading}
                            >
                                {!isLoading
                                    ? !admin
                                        ? "Ajouté"
                                        : "Modifié"
                                    : "Loading..."}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddOrUpdateSubAdminModal;

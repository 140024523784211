import React, { useState } from 'react';
import { Form, Container, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { MdKeyboardArrowRight, MdFileUpload } from "react-icons/md";
import image from '../../../../assets/image-admin/Image.png';
import './AjouVendeur.css';

const AjouVendeur = () => {
  const [subVendorsCount, setSubVendorsCount] = useState(0);

  const incrementSubVendors = () => setSubVendorsCount(prevCount => prevCount + 1);
  const decrementSubVendors = () => setSubVendorsCount(prevCount => Math.max(prevCount - 1, 0));

  console.log('AjouVendeur component is rendered');

  return (
    <Container className="mt-5">
      <Form>
        <h5 className='mt-5 pt-5'> <a href="/vendeurs" className="text-decoration-none text-dark">Vendeurs</a> <MdKeyboardArrowRight /> Ajouter un vendeur</h5>
        <h6 className='mt-5'><span>INFORMATIONS GÉNÉRALES</span></h6>
        <Row className="mb-3 mt-3">
          <Col>
            <Form.Group controlId="formBasicNom">
              <Form.Label>Nom</Form.Label>
              <Form.Control type="text" placeholder="Entrez votre nom" className="large-input" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicPrenom">
              <Form.Label>Prénom</Form.Label>
              <Form.Control type="text" placeholder="Entrez votre prénom" className="large-input" />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formBasicPhone">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control type="text" placeholder="Entrez votre numéro" className="large-input" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Entrez votre email" className="large-input" />
            </Form.Group>
          </Col>
        </Row>
          
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formBasicSexe">
              <Form.Label>Sexe</Form.Label>
              <Form.Select className="large-input">
                <option value="Homme">Homme</option>
                <option value="USD">Femme</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formBasicNomBoutique">
              <Form.Label>Nom de la Boutique</Form.Label>
              <Form.Control type="text" placeholder="Entrez le nom de la boutique" className="large-input" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicNomDomaine">
              <Form.Label>Nom de Domaine</Form.Label>
              <Form.Control type="text" placeholder="Entrez le nom de domaine" className="large-input" />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formBasicDevise">
              <Form.Label>Devise</Form.Label>
              <Form.Select className="large-input">
                <option value="XOF">XOF</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <h5>STRIPE</h5>
          <Col>
            <Form.Group controlId="formBasicStripeEndpointSecret">
              <Form.Label>Stripe endpoint secret:</Form.Label>
              <Form.Control type="text" placeholder="|" className="large-input" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicStripePublishableKey">
              <Form.Label>Stripe publishable key:</Form.Label>
              <Form.Control type="text" placeholder="|" className="large-input" />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formBasicStripeSecretKey">
              <Form.Label>Stripe secret key:</Form.Label>
              <Form.Control type="text" placeholder="|" className="large-input" />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <h5>WAVE</h5>
          <Col>
            <Form.Group controlId="formBasicWaveApiKey">
              <Form.Label>Wave API Key</Form.Label>
              <Form.Control type="text" placeholder="|" className="large-input" />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <h5>Charte Graphique Boutique</h5>
          <Col>
            <Form.Group controlId="formBasicCouleur">
              <Form.Label>Couleur</Form.Label>
              <Form.Control type="text" placeholder="|" className="large-input" />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <h5>Logo</h5>
          <Col md={6} className="d-flex align-items-center bg border-none">
            <img src={image} alt="Default Logo" className="img-thumbnail" style={{ width: '800px', height: '200px' }} />
            <div className="ml-5 b">Upload your webinar Thumbnail here. Important guidelines: 1200x800 pixels or 12:8 Ratio. Supported format: .jpg, .jpeg, or .png
              <Button variant="" className="d-flex align-items-center text mt-5">
                <h5> Upload image <MdFileUpload className="ml-5" /></h5>
                <Form.Control type="file" className="d-none" />
              </Button>
            </div>
          </Col>
          <Col md={6}></Col>
        </Row>

        <Row className="mb-3 mt-5">
          <h5>Habilitation</h5>
        </Row>

        <Row className="mb-3 custom-radio">
          <Col>
            <Form.Check
              type="radio"
              label="Meilleures ventes"
              name="radioGroupTop"
              id="radio1Top"
            />
          </Col>
          <Col>
            <Form.Check
              type="radio"
              label="Ajout commandes"
              name="radioGroupTop"
              id="radio2Top"
            />
          </Col>
        </Row>

        <Row className="mb-3 custom-radio">
          <Col>
            <Form.Check
              type="radio"
              label="Cout total des ventes"
              name="radioGroupBottom"
              id="radio1Bottom"
            />
          </Col>
          <Col>
            <div className="d-flex align-items-center">
              <Form.Check
                type="radio"
                label="Ajout sous-vendeurs"
                name="radioGroupBottom"
                id="radio2Bottom"
              />
              <InputGroup className="ml-2 InputGroup" style={{ maxWidth: '100px' }}>
                <Button variant="light" size="sm" onClick={decrementSubVendors}>-</Button>
                <Form.Control
                  type="text"
                  value={subVendorsCount}
                  readOnly
                  className="text-center no-border-input"
                  style={{ width: '50px', padding: '0.25rem' }}
                />
                <Button variant="light" size="sm" onClick={incrementSubVendors}>+</Button>
              </InputGroup>
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button variant="primary" type="submit" className="custom-button mt-5">
            Enregistrer
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AjouVendeur;

import React, { useState } from 'react';
import { Form, Container, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { MdKeyboardArrowRight, MdFileUpload } from "react-icons/md";
import image from '../../../../assets/image-admin/Image.png';
import { Link, NavLink, useParams, useLocation, useNavigate} from 'react-router-dom';


const DetailsCommande = () => {
  const { slug } = useParams<{ slug: string }>();
  let { state } = useLocation();
  const navigate = useNavigate();

  console.log('Details Commande ', state);



  return (
    <div className="form-register-tabs-component mt-10">
      
      <div className="content-detail-commande-page m-5 ">
        <div className="row">
          <div className="content-title-form-register pb-4">
            <h3
              className="title-form-register mt-5"
              style={{ cursor: "pointer" }}
            >
              <span className="">Details Commande</span>{" "}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3 col-left-detail-commande">
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Non client</h3>
                <div className="sm-value">
                  {state?.user ? state?.user?.prenom + " " + state?.user?.nom : state?.prenom_client+ " " +state?.prenom_client}
                </div>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Email client</h3>
                <div className="sm-value">
                  {state?.user ? state?.user?.email : state?.email_client}
                </div>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">
                  Téléphone
                </h3>
                <div className="sm-value">
                  {state?.user ? state?.user?.telephone : state?.telphone_client}
                </div>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">
                  Adrésse
                </h3>
                <div className="sm-value">
                  {state?.user ? state?.user?.adresse : state?.adresse}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3 col-right-detail-commande">
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Mode de paiement</h3>
                <div className="sm-value">
                  {state?.mode_de_paiement == "ORANGE_SN_API_CASH_OUT" &&
                    "Orange Money"}
                  {state?.mode_de_paiement == "WAVE_SN_API_CASH_OUT" && "Wave"}
                  {state?.mode_de_paiement == "CASH" &&
                    "Paiement à la livraison"}
                  {state?.mode_de_paiement == "BANK_TRANSFER_SN_API_CASH_OUT" &&
                    "Virement Bancaire"}
                </div>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Statut de la commande</h3>
                <div className="sm-value">
                  {state?.statut == "EN_COURS_DE_TRAITEMENT" &&
                    "En cours de taitement"}
                  {state?.statut == "ANNULE" && "Annelé"}
                  {state?.statut == "EN_COURS_DE_LIVRAISON" &&
                    "En cour de livraison"}
                  {state?.statut == "LIVRE" && "Livré"}
                </div>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Montant total</h3>
                <div className="sm-value">
                  {state?.montant_total }
                </div>
              </div>
            </div>
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Mode de paiement</h3>
                <div className="sm-value">
                  {state?.mode_de_paiement == "ORANGE_SN_API_CASH_OUT" &&
                    "Orange Money"}
                  {state?.mode_de_paiement == "WAVE_SN_API_CASH_OUT" && "Wave"}
                  {state?.mode_de_paiement == "CASH" &&
                    "Paiement à la livraison"}
                  {state?.mode_de_paiement == "BANK_TRANSFER_SN_API_CASH_OUT" &&
                    "Virement Bancaire"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCommande;

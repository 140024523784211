import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { TypeQuery,PaginationResults } from "../user/user.type";
import { Vendeur, VendeurFormData } from "./vendeur.type";

export const VendeurApi = createApi({
  reducerPath: "vendeurApi",
  tagTypes: [
    "vendeur",
    "vendeurList",
    "vendeurByVisitor",
    "vendeurById",
    "allVendeurList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListvendeurAdmin: builder.query<PaginationResults<Vendeur>, TypeQuery>({
      providesTags: ["vendeurList"],
      query: (query) => QueryUrl("vendeurs", query),
    }),
    getVendeurList: builder.query<PaginationResults<Vendeur>, TypeQuery>({
      providesTags: ["vendeur"],
      query: (query) => QueryUrl("payment-method/", query),
    }),
    addOrEditVendeur: builder.mutation<Vendeur,{ id?: string; data: VendeurFormData | FormData }>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            VendeurApi.util.invalidateTags([
              "vendeurList",
              "vendeurByVisitor",
              "vendeur",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `payment-method/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `payment-method/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteVendeur: builder.mutation<Vendeur, string>({
      query: (id) => ({
        url: `payment-method/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "vendeur",
        "vendeurList",
        "vendeurByVisitor",
      ],
    }),
    vendeurById: builder.query<Vendeur | any, string>({
      query: (id) => `payment-method/${id}/`,
      providesTags: ["vendeurById"],
    }),
  }),
});

export const {
  useAddOrEditVendeurMutation,
  useDeleteVendeurMutation,
  useGetListvendeurAdminQuery,
  useGetVendeurListQuery,
} = VendeurApi;
